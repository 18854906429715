import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  Background,
  PaginatedGrid,
  componentIterator,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class AboutPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"about-page"}>
          <Grid
            stackable={true}
            doubling={true}
            columns={2}
            className={"about-grid"}
          >
            <Grid.Column className={"content-column"}>
              <Content className={"about-content"} textAlign={"left"}>
                <Header as={"h1"} className={"about-us-header"}>
                  ABOUT US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={false}
                  content={
                    <div>
                      <p>
                        In a day like no other, in a matter of seconds, my life
                        changed forever. I was a single mother full of dreams
                        and no choice but to leave everything behind. The
                        earthquake have taken every possible to offer my kids
                        their basic needs. I could not afford to take them to
                        school or buy them an ice cream. I knew at that moment
                        that that I needed to pack my bags and start somewhere
                        that would allow me to bring food to our table.
                      </p>
                      <p>
                        It was never easy as every new beginning. I tear up
                        almost every day, however, all those sad moments help me
                        be who I am today. I believe that everything happens for
                        a reason. I could not have done it without my kids who
                        has always been my engine and reason for a better
                        tomorrow. Family is very important to me, and I wanted
                        to create something that allow families to be together
                        enjoying each other company.
                      </p>
                      <p>
                        I remember growing up spending hours at the dinner table
                        and how wonderful it felt inside. I decided to open
                        Burritos N Ribs. Our small family restaurant with our
                        homemade recipes. We were beyond excited and could not
                        believe. Two weeks later, COVID show up at everyone
                        doors. It was the beginning of this unknown pandemic
                        that almost put us out of business. I could not let go
                        of this dream.
                      </p>
                      <p>
                        We show up every day with a big smile in our face. As
                        time went by everything got a lot better, and we feel
                        more than lucky to be here today shaking hands with
                        everyone who walks through our doors. It feels like
                        home.
                      </p>
                    </div>
                  }
                />
              </Content>
            </Grid.Column>
            <Grid.Column className={"image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <PaginatedGrid
                  rows={2}
                  columns={4}
                  className={"image-gallery-grid"}
                  mobile={{ columns: 2, rows: 4, stackable: false }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    }),
                    component: <Image background={true} className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Background.Pattern>
            </Background>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(pageType: { eq: "About" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
